<template>
  <div class="workflows">
    <div>
      <h3>{{this.projectName}} - {{this.step}}<font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></h3>
      <div class="job-select"><select class="job-select-filter form-control mt-1"></select></div>
      <div class="job-details-container">
        <h2>Details</h2>
        <div class="job-details-row">
          <div class="col">
            <div><strong>Project:</strong>{{this.projectName}}</div>
            <div><strong>Workflow ID:</strong>{{this.workflowId}}</div>
            <div><strong>Job ID: </strong>{{this.jobId}}</div>
          </div>
          <div class="col">
            <div><strong>Step:</strong>{{this.step}}</div>
            <div><strong>UI ID:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
          </div>
        </div>
      </div>
    </div>
    <div class="job-details-container">
      <h2>Status</h2>
      <div class="job-details-row">
        <div class="col">
          <div><strong>P2020 Status:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
          <div><strong>AWS Status:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
          <div><strong>Job Attempts:</strong> <font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
        </div>
        <div class="col">
          <div><strong>P2020 Status Reason:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
          <div><strong>AWS Job Status Reason:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
          <div><strong>Container Status Reason:</strong><font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/></div>
       </div>
      </div>
    </div>
    <div class="job-details-container"><h2>AWS Job Details</h2></div>
    <div class="job-details-container"><h2>Environment Variables</h2></div>
  </div>
</template>

<script>
export default {
  name: 'JobDetailsTemplate',
  props: {
    workflowId: String,
    projectName: String,
    step: String,
    jobId: String,
    selectedWorkflowJobAction: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col{
  flex:1;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  line-height: 2;
}

.job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

.job-details-row{
  display: flex;
}
.loading-component {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
}

.job-select{
  justify-content: center;
  margin: 1rem auto;
  display:flex;
}

.job-select-filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
