<template>
  <div>
    <font-awesome-icon v-if="jobLoading" :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
    <div class="job-details-container">
      <h2> Output Files
        <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
        <span v-if="!hide && !jobOutputsRefreshing" class="refresh-job-outputs" @click="refreshJobOutputs">
          <img style="width:1.5rem;height:1.5rem;" :src=arrowsRotateIcon title="refresh data"/>
        </span>
        <span v-if="!hide && jobOutputsRefreshing" class="refresh-job-inputs">
        <font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
      </span>
      </h2>
      <SearchBar v-if="!hide" @searchList="searchFiles" @resetList="cancelFileSearch" @change="primeJobOutputSearch"/>
      <div class="output-files" v-for="file, index in files" :key="index">
        <OutputFile :workflow="this.workflow" :project="this.project" :file="file" v-if="!jobLoading && !hide"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Collapser from '../Generics/Collapser.vue'
import OutputFile from '../JobDetails/OutputFile.vue'
import SearchBar from '../Generics/SearchBar.vue'

export default {
  components: {
    Collapser,
    OutputFile,
    SearchBar
  },
  props: {
    jobId: String,
    title: String,
    files: Array,
    workflow: String,
    project: String,
    step: String
  },
  computed: {
    ...mapGetters({
      job: 'getJob',
      jobLoading: 'jobLoading',
      jobOutputsRefreshing: 'jobOutputsRefreshing'
    })
  },
  data() {
    return {
      hide:true,
      searchTerm: "",
      arrowsRotateIcon: require('../../assets/arrows-rotate-solid.svg')
    }
  },
  methods: {
     ...mapActions([
      'populateJobOutputSearch',
      'searchJobOutputFiles',
      'clearJobOutputSearch',
      'loadJobFilesOutputStatusOnly'
    ]),
    async refreshJobOutputs(){
      let args = {workflowId: this.workflow, projectName: this.project, step:this.step, jobId: this.jobId}
      await this.loadJobFilesOutputStatusOnly(args)
    },
    async cancelFileSearch(){
      let args = {workflowId: this.workflow, projectName: this.project, step:this.step, jobId: this.jobId}
      this.clearJobOutputSearch(args)
    },
    toggleElement(){
      this.hide = !this.hide
    },
    searchFiles(){
      this.searchJobOutputFiles()
    },
    primeJobOutputSearch(e){
      this.populateJobOutputSearch(e.target.value)
    }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }

  .output-files{
    padding-top:1rem;
    padding-bottom: 1rem;
  }

  .refresh-job-outputs{
    cursor:pointer;
    margin-left:2rem;
  }

  .loading-component {
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
  }
</style>