<template>
  <div class="job-details-container">
    <div class="job-details-container">
      <h2>Timing Information
        <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
      </h2>
      <div class="job-details-row"  v-if="!hide">
        <div class="col">
          <div v-if="batch_created!='01/01/1970, 01:00:00'"><strong>Batch Job Created:</strong> {{batch_created}}</div>
          <div v-if="transition_runnable_at!='01/01/1970, 01:00:00'"><strong>Transition Runnable at:</strong> {{transition_runnable_at }}</div>
          <div v-if="transition_starting_at!='01/01/1970, 01:00:00'"><strong>Transition Starting at:</strong> {{transition_starting_at }}</div>
          <div v-if="ecs_pull_started_at!='01/01/1970, 01:00:00'"><strong>ECS Pull Started at:</strong> {{ecs_pull_started_at}}</div>
          <div v-if="ecs_pull_stopped_at!='01/01/1970, 01:00:00'"><strong>ECS Pull Stopped at:</strong> {{ecs_pull_stopped_at}}</div>
        </div>
        <div class="col">
          <div v-if="batch_started_at!='01/01/1970, 01:00:00'"><strong>Batch Job Started:</strong> {{batch_started_at }}</div>
          <div v-if="ecs_task_started_at!='01/01/1970, 01:00:00'"><strong>ECS Task Started at:</strong> {{ecs_task_started_at}}</div>
          <div v-if="transition_running_at!='01/01/1970, 01:00:00'"><strong>Transition Running at:</strong> {{transition_running_at }}</div>
          <div v-if="batch_stopped_at!='01/01/1970, 01:00:00'"><strong>Batch Job Stopped:</strong> {{batch_stopped_at }}</div>
          <div v-if="transition_succeeded_at!='01/01/1970, 01:00:00'"><strong>Transition Succeeded at:</strong> {{transition_succeeded_at}}</div>
          <div v-if="transition_failed_at!='01/01/1970, 01:00:00'"><strong>Transition Failed at:</strong> {{transition_failed_at }}</div></div>        
      </div>
    </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
export default {
  components: {
    Collapser
  },
  props: {
    batch_created: String,
    batch_started_at: String,
    batch_stopped_at: String,
    transition_runnable_at: String,
    transition_starting_at: String,
    transition_running_at: String,
    transition_succeeded_at: String,
    transition_failed_at: String,
    ecs_pull_started_at: String,
    ecs_pull_stopped_at: String,
    ecs_task_started_at: String,
    hidden: Boolean
  },
  data() {
    return {
      hide:this.hidden
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-row{
    display: flex;
  }
</style>

