<template>
  <div class="job-details-container">
    <Notification />
    <h2>Details
      <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
    </h2>
    <div class="job-details-row" v-if="!hide">
      <div class="col">
        <div><strong>Project:</strong> {{ project }}</div>
        <div><strong>Workflow ID:</strong> {{ workflow_id }}</div>
        <div><strong>Job ID: </strong> {{job_id}}</div>
      </div>
      <div class="col">
        <div><strong>Step:</strong> {{ step }}</div>
        <div><strong>UI ID:</strong> {{ ui_id }}</div>
        <div><router-link target="_blank"  :to="{ name: 'JobConfig', params: {project: this.project, workflow_id: this.workflow_id, step: this.step, job_id: this.job_id, status: this.status }}">Job Configuration</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
import Notification from '../Generics/Notification.vue';

export default {
  components: {
    Notification,
    Collapser
  },
  props: {
    project: String,
    workflow_id: String,
    step: String,
    ui_id: String,
    job_id: String,
    status: String
  },
  data() {
    return {
      hide:false
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-container a{
    text-decoration: none;
  }

  .job-details-row{
    display: flex;
  }
</style>

