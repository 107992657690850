<template>
  <div class="job-details-container">
    <h2>Environment Variables
      <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
    </h2>
    <div class="job-details-row" v-if="!hide">
      <div class="col">
        <div  v-for="(value, propertyName) in variables" :key="propertyName"><strong>{{propertyName}}</strong> {{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
export default {
  components: {
    Collapser
  },
  props: {
    variables: Object
  },
  data() {
    return {
      hide:true
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
    border: 1px solid gray;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-row{
    display: flex;
  }
</style>

