<template>
  <div class="workflows">
    <h1>Job Details</h1>
    <JobDetailsTemplate v-if="jobLoading"
      :workflowId="this.workflowId"
      :projectName="this.projectName"
      :step="this.step"
      :jobId="this.jobId"
    />
    <h3 v-if="job.definition!==undefined&&!jobLoading">{{job.definition.job_definition}}
      <span id="refresh-job-details" @click="refreshJob">
        <img style="width:1.5rem;height:1.5rem;" :src=arrowsRotateIcon title="refresh data"/>
      </span>
    </h3>
    <div id="job-details-container" v-show="!jobLoading" v-if="job.definition !== undefined && job.runtime_details !== undefined">
      <div v-show="this.workflowJobActions!==undefined"  id="workflow-job-select">  
        <select id="workflow-job-action-filter"
                name="workflow-job-action-filter"
                class="form-control mt-1"
                v-model="selectedWorkflowJobAction"
                @change="selectWorkflowJobAction">
          <option value="" disabled selected>Job Actions</option>    
          <option v-for="wa in workflowJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
        </select>
        <button v-show="selectedWorkflowJobAction !== undefined" type="button" class="app-button primary-button" v-on:click="updateWorkflowJobStatusAndRefresh">Go</button>
      </div>
      <JobDetails 
        :project="job.definition.project" 
        :workflow_id="job.definition.workflow_id" 
        :step="job.definition.step" 
        :ui_id="job.definition.ui_id" 
        :job_id="job.definition.id"
        :status="job.p2020_status"/>
      <JobStatus 
        :aws_status="job.aws_status" 
        :aws_job_status_reason="job.runtime_details.aws_job_status_reason" 
        :container_reason="job.runtime_details.container_reason"
        :container_exit_code="job.runtime_details.container_exit_code"
        :job_attempts="job.runtime_details.job_attempts"
        :log_stream_name="job.runtime_details.log_stream_name"
        :p2020_status="job.p2020_status"
        :p2020_status_reason="job.runtime_details.p2020_status_reason"
      />
      <TimingInformation v-if="job.runtime_details.batch_job_created_at !== undefined && 
        job.runtime_details.batch_job_started_at !== undefined"
        :batch_created="new Date(job.runtime_details.batch_job_created_at * 1).toLocaleString()"
        :batch_started_at="new Date(job.runtime_details.batch_job_started_at * 1).toLocaleString()"
        :batch_stopped_at="new Date(job.runtime_details.batch_job_stopped_at * 1).toLocaleString()"
        :transition_runnable_at="new Date(job.runtime_details.transition_runnable_at * 1).toLocaleString()"
        :transition_starting_at="new Date(job.runtime_details.transition_starting_at * 1).toLocaleString()"
        :transition_running_at="new Date(job.runtime_details.transition_running_at * 1).toLocaleString()"
        :transition_succeeded_at="new Date(job.runtime_details.transition_succeeded_at * 1).toLocaleString()"
        :transition_failed_at="new Date(job.runtime_details.transition_failed_at * 1).toLocaleString()"
        :ecs_pull_started_at="new Date(job.runtime_details.ecs_pull_started_at * 1).toLocaleString() "
        :ecs_pull_stopped_at="new Date(job.runtime_details.ecs_pull_stopped_at * 1).toLocaleString()"
        :ecs_task_started_at="new Date(job.runtime_details.ecs_task_started_at * 1).toLocaleString()"
        :hidden="true"
      />
      <InputFiles v-if="!jobFilesLoading && jobFiles.inputs!==undefined && jobFiles.inputs.length>0" :project="job.definition.project" :workflow="job.definition.workflow_id" :step="this.step" :files="jobFiles.inputs" :jobId="jobId"/>
      <OutputFiles v-if="!jobFilesLoading && jobFiles.outputs!==undefined && jobFiles.outputs.length>0" :project="job.definition.project" :workflow="job.definition.workflow_id" :step="this.step" :files="jobFiles.outputs" :jobId="jobId"/>
      <AWSJobDetails 
        :job_queue="job.runtime_details.job_queue"
        :job_name="job.runtime_details.job_name"
        :aws_job_id="job.runtime_details.aws_job_id"
        :container="job.runtime_details.container"
        :container_version="job.runtime_details.container_version"
        :container_account="job.runtime_details.container_account"
        :container_exit_code="job.runtime_details.container_exit_code"
        :container_parameters="job.runtime_details.container_account"
        :cpu_architecture="job.runtime_details.cpu_architecture"
        :docker_version="job.runtime_details.docker_version"
        :aws_job_definition="job.runtime_details.aws_job_definition"
        :job_role="job.runtime_details.job_role"
        :job_vcpus="job.runtime_details.job_vcpus"
        :job_memory="job.runtime_details.job_memory"
        :job_timeout_seconds="job.runtime_details.job_timeout_seconds"
        :retry_strategy="job.runtime_details.retry_strategy"
        :container_command="job.runtime_details.container_command"
        :resource_requirements="job.runtime_details.resource_requirements"
        :platform_capabilities="job.runtime_details.platform_capabilities"
        :ulimits="job.runtime_details.ulimits"
        :ecs_cluster="job.runtime_details.ecs_cluster"
        :ecs_task_id="job.runtime_details.ecs_task_id"
        :ecs_task_group="job.runtime_details.ecs_task_group"
        :ec2_instanceId="job.runtime_details.ec2_instanceId"
        :availability_zone="job.runtime_details.availability_zone"
        :ec2_instance_type="job.runtime_details.ec2_instance_type"
        :ec2_instance_vcpu="job.runtime_details.ec2_instance_vcpu"
        :ec2_instance_memory="job.runtime_details.ec2_instance_memory"
        :ecs_agent_version="job.runtime_details.ecs_agent_version"
        :ecs_instance_registered_at="job.runtime_details.ecs_instance_registered_at"
        :ecs_instance_running_tasks="job.runtime_details.ecs_instance_running_tasks"
        :ami_id="job.runtime_details.ami_id"
      />
      <EnvVariables  v-if="job.definition.variables !=undefined" :variables="job.definition.variables"/>
      </div>
      <ConfirmModal v-if="confirmingChoice" @confirm="handleConfirm" :message="this.warningMessage"/>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import JobDetails from '../components/JobDetails/JobDetails.vue'
import JobStatus from '../components/JobDetails/JobStatus.vue'
import InputFiles from '../components/JobDetails/InputFiles.vue'
import OutputFiles from '../components/JobDetails/OutputFiles.vue'
import JobConfiguration from '../components/JobDetails/JobConfiguration.vue'
import AWSJobDetails from '../components/JobDetails/AWSJobDetails.vue'
import TimingInformation from '../components/JobDetails/TimingInformation.vue'
import EnvVariables from '../components/JobDetails/EnvVariables.vue'
import JobDetailsTemplate from '../components/Templates/JobDetailsTemplate.vue'
import ConfirmModal from '../components/Generics/ConfirmModal.vue'

export default {
  name: 'Job',
  components: {
    JobDetails,
    JobStatus,
    InputFiles,
    OutputFiles,
    JobConfiguration,
    AWSJobDetails,
    TimingInformation,
    EnvVariables,
    JobDetailsTemplate,
    ConfirmModal
  },
  props: {
    workflowId: String,
    projectName: String,
    step: String,
    jobId: String,
    selectedWorkflowJobAction: String
  },
  data(){
    return {
      confirming: false,
      arrowsRotateIcon: require('../assets/arrows-rotate-solid.svg'),
    }
  },
  computed: {
    ...mapGetters({
      job: 'getJob',
      jobFiles: 'getJobFiles',
      jobLoading: 'jobLoading',
      jobFilesLoading: 'jobFilesLoading',
      workflowJobActions: 'getWorkflowJobActions',
      selectedWorkflowJobAction: 'getSelectedJobAction',
    }),
    confirmingChoice(){
      return this.confirming
    }
  },
  methods: {
    ...mapActions(['loadJob', 'loadJobFiles', 'loadJobFilesStatusOnly', 'loadWorkflow', 'loadJobActionsForSingleJob', 'updateWorkflowJobStatus', 'selectWorkflowJobAction']),
    async updateWorkflowJobStatusAndRefresh(){
      if(this.selectedWorkflowJobAction==="remove"){
        this.warningMessage='WARNING: the remove command WILL DELETE DATA created by each selected job. Please confirm or cancel action'
        this.confirming=true
      }
      else if(this.selectedWorkflowJobAction==="force_run"){
        this.warningMessage='WARNING: Each selected job WILL RUN WITH SOME INPUT DATA MISSING. Please confirm or cancel action'
        this.confirming=true
      }
      else{
        let jobArgs = {
        "project":  this.job.definition.project, 
        "workflow_id": this.job.definition.workflow_id, 
        "jobs": [{
          "step":this.job.definition.step,
          "job_id":this.job.definition.id
          }
        ]}
        await this.updateWorkflowJobStatus(jobArgs);
      }
    },
    async refreshJob(){
      let args = {workflowId: this.workflowId, projectName: this.projectName, step:this.step, jobId: this.jobId}
      await this.loadWorkflow({workflowId: this.workflowId, projectName: this.projectName})
      await this.loadJob(args)
      await this.loadJobActionsForSingleJob(this.job)
    },
    async refreshJobFiles(){
      let args = {workflowId: this.workflowId, projectName: this.projectName, step:this.step, jobId: this.jobId}
      await this.loadJobFilesStatusOnly(args)
    },
    async handleConfirm(result){
      if(result.response){
        let jobArgs = {
          "project":  this.projectName, 
          "workflow_id": this.workflowId, 
          "jobs": [{
          "step":this.job.definition.step,
          "job_id":this.job.definition.id
          }]}
        await this.updateWorkflowJobStatus(jobArgs); 
      }
      this.confirming=false
    }
  },
  async mounted () {
    let args = {workflowId: this.workflowId, projectName: this.projectName, step:this.step, jobId: this.jobId}
    await this.loadWorkflow({workflowId: this.workflowId, projectName: this.projectName})
    await this.loadJob(args)
    await this.loadJobFiles(args)
    await this.loadJobActionsForSingleJob(this.job)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  font-weight: bold;
}

li > span {
  font-weight: normal;
}

.job-details-container {
  border: 0.5px solid lightgray;
}

.job-details-row{
  display: flex;
}

.col{
  flex:1;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  line-height: 2;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}


#workflow-job-action-filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

#workflow-job-select{
  justify-content: center;
  margin: 1rem auto;
  display:flex;
}

#workflow-job-select button{
  margin-left: 1rem;
}

#refresh-job-details{
  margin-left: 1rem;
  cursor: pointer;
  width:1rem;
}
</style>
