<template>
  <div class="job-details-container">
    <div class="job-details-container">
      <h2>Status
        <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
      </h2>
      <div class="job-details-row" v-if="!hide">
        <div class="col">
          <div><strong>P2020 Status:</strong> {{ p2020_status }}</div>
          <div><strong>AWS Status:</strong> {{ aws_status }}</div>
          <div><strong>Job Attempts:</strong> {{ job_attempts }}</div>
          <div v-if="log_stream_name!==undefined"><a :href="'https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Fbatch$252Fjob/log-events/' + log_stream_name.replaceAll('/', '$252F')" target='#'>Log Stream</a></div>
        </div>
        <div class="col">
          <div><strong>P2020 Status Reason: {{p2020_status_reason}}</strong></div>
          <div><strong>AWS Job Status Reason:</strong> {{ aws_job_status_reason }}</div>
          <div><strong>Container Status Reason:</strong> {{ container_reason }}</div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
export default {
  components: {
    Collapser
  },
  props: {
    aws_status: String,
    aws_job_status_reason: String,
    container_reason: String,
    container_exit_code: String,
    job_attempts: String,
    log_stream_name: String,
    p2020_status: String,
    p2020_status_reason: String
  },
  data() {
    return {
      hide:false
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }
  
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-row{
    display: flex;
  }
</style>