<template>
  <div class="job-details-container">
    <h2>Environment Variables
    <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
    </h2>
    <div class="job-details-row" v-if="!hide">
      <div class="col">
        <div><strong>P2020 Template:</strong> {{ p2020_template }}</div>
        <div><strong>P2020 Run File:</strong> {{ p2020_run_file }}</div>
        <div><strong>L1C Output Metadata:</strong> {{ lic_output_metadata }}</div>
        <div><strong>L1C Output Safe Directory:</strong> {{ l1c_output_safe_dir }}</div>
      </div>
      <div class="col">
        <div><strong>L1 Product ID:</strong> {{ l1_product_id }}</div>  
        <div><strong>Output Path:</strong> {{ output_path }}</div>
        <div><strong>P2020 Workflow:</strong>{{ workflow_template }}</div>
        <div><strong>P2020 Workflow ID:</strong> {{ workflow_id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
export default {
  components: {
    Collapser
  },
  props: {
    p2020_template: String,
    p2020_run_file: String,
    lic_output_metadata: String,
    l1c_output_safe_dir: String,
    l1_product_id: String,
    output_path: String,
    status: String,
    ui_id: String,
    workflow_template: String,
    workflow_id: String,
  },
  data() {
    return {
      hide:true
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-row{
    display: flex;
  }
</style>

