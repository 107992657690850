<template>
  <div>
    <font-awesome-icon v-if="jobFilesLoading" :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
    <div class="job-details-container">
    <h2> Input Files 
      <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
      <span v-if="!hide && !jobInputsRefreshing" class="refresh-job-inputs" @click="refreshJobInputs">
        <img style="width:1.5rem;height:1.5rem;" :src=arrowsRotateIcon title="refresh data"/>
      </span>
      <span v-if="!hide && jobInputsRefreshing" class="refresh-job-inputs">
        <font-awesome-icon :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
      </span>
    </h2>
    <SearchBar v-if="!hide" @searchList="searchFiles" @resetList="cancelFileSearch" @change="primeInputSearch"/>
      <div class="input-files" v-for="file, index in files" :key="index">
        <InputFile :workflow="this.workflow" :project="this.project" :file="file" v-if="!jobFilesLoading && !hide"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Collapser from '../Generics/Collapser.vue'
import InputFile from '../JobDetails/InputFile.vue'
import SearchBar from '../Generics/SearchBar.vue'

export default {
  components: {
    Collapser,
    InputFile,
    SearchBar
  },
  props: {
    jobId: String,
    title: String,
    files: Array,
    workflow: String,
    project: String,
    step: String
  },
  computed: {
    ...mapGetters({
      job: 'getJob',
      jobFilesLoading: 'jobFilesLoading',
      jobInputsRefreshing: 'jobInputsRefreshing',
    })
  },
  data() {
    return {
      hide:true,
      searchTerm: "",
      arrowsRotateIcon: require('../../assets/arrows-rotate-solid.svg')
    }
  },
  methods: {
     ...mapActions([
      'populateJobInputSearch',
      'searchJobInputFiles',
      'clearJobInputSearch',
      'loadJobFilesInputStatusOnly'
    ]),
    async refreshJobInputs(){
      let args = {workflowId: this.workflow, projectName: this.project, step:this.step, jobId: this.jobId}
      await this.loadJobFilesInputStatusOnly(args)
    },
    async cancelFileSearch(){
      let args = {workflowId: this.workflow, projectName: this.project, step:this.step, jobId: this.jobId}
      this.clearJobInputSearch(args)
    },
    toggleElement(){
      this.hide = !this.hide
    },
    searchFiles(){
      this.searchJobInputFiles()
    },
    primeInputSearch(e){
      this.populateJobInputSearch(e.target.value)
    },
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }

  .job-details-container{
    border-bottom:1px solid gray
  }

  .input-files{
    padding-top:1rem;
    padding-bottom: 1rem;
  }

  .refresh-job-inputs{
    cursor:pointer;
    margin-left:2rem;
  }

  .loading-component {
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
  }
</style>