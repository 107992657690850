<template>
  <div class="job-details-container">
      <h2>AWS Details
      <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
      </h2>
      <div class="job-details-row" v-if="!hide">
        <div class="col">
          <div><strong>AMI ID:</strong><a :href="'https://' + region + '.console.aws.amazon.com/ec2/home?region=' + region + '#ImageDetails:imageId=' + ami_id" target="#">{{ ami_id }}</a></div>
          <div><strong>AWS Job ID:</strong><a :href="'https://' + region + '.console.aws.amazon.com/batch/home?region=' + region + '#jobs/detail/' + aws_job_id" target="#"> {{ aws_job_id }}</a></div>
          <div><strong>AWS Job Definition: </strong><a :href="'https://' + region + '.console.aws.amazon.com/batch/home?region=' + region + '#job-definition/ec2/detail/arn:aws:batch:' + region + ':' + container_parameters + ' :job-definition/' + aws_job_definition">{{aws_job_definition}}</a></div>
          <div><strong>AWS Availability Zone:</strong>{{ availability_zone }}</div>
          <div><strong>Container:</strong><a :href="' https://' + region + '.console.aws.amazon.com/ecr/repositories/private/' + container_parameters + '/' + container">{{ container }}</a></div>
          <div><strong>Container Account</strong>{{container_account}}</div>
          <div><strong>Container Command:</strong> {{ container_command }}</div>
          <div><strong>Container Exit Code:</strong> {{ container_exit_code }}</div>
        </div>
        <div class="col">
          <div><strong>Container Parameters:</strong>{{ container_parameters }}</div>
          <div><strong>Container Version:</strong> {{ container_version }}</div>
          <div><strong>CPU Architecture:</strong> {{ cpu_architecture }}</div>
          <div><strong>ECS Cluster:</strong><a :href="'https://' + region + '.console.aws.amazon.com/ecs/home?region=' + region + '#/clusters/' + ecs_cluster + '/services'" target="#">{{ ecs_cluster }}</a></div>
          <div><strong>ECS Task Id:</strong> {{ ecs_task_id }}</div>
          <div><strong>Docker Version:</strong> {{ docker_version }}</div>
          <div><strong>ECS Instance Registered at:</strong>{{ecs_instance_registered_at  }}</div>
          <div><strong>ECS Instance Running Tasks:</strong> {{ ecs_instance_running_tasks }}</div>
        </div>
        <div class="col">
          <div><strong>ECS Agent Version:</strong> {{ ecs_agent_version }}</div>
          <div><strong>ECS Task Group:</strong> {{ ecs_task_group }}</div>
          <div><strong>EC2 Instance Id:</strong><a :href="'https://' + region + '.console.aws.amazon.com/ec2/home?region=' + region + '#Instances:instanceId=' + ec2_instanceId" target="#">{{ ec2_instanceId }}</a></div>        
          <div><strong>EC2 Instance Type:</strong> {{ ec2_instance_type }}</div>
          <div><strong>EC2 Instance VCPU:</strong> {{ ec2_instance_vcpu }}</div>
          <div><strong>EC2 Instance Memory:</strong> {{ ec2_instance_memory }}</div>
          <div><strong>Job Queue:</strong><a :href="'https://' + region + '.console.aws.amazon.com/batch/home?region=' + region + '#queues/detail/arn:aws:batch:' + region + ':' + container_parameters + ':job-queue/' + job_queue" target="#">{{ job_queue }}</a></div>
          <div><strong>Job Name:</strong> {{ job_name }}</div>
        </div>
        <div class="col">
          <div><strong>Job Role:</strong><a :href="'https://us-east-1.console.aws.amazon.com/iamv2/home?region=' + region + '#/roles/details/' + job_role + '?section=permissions'" target="#">{{ job_role }}</a></div>
          <div><strong>Job VCPUs:</strong> {{ job_vcpus }}</div>
          <div><strong>Job Memory:</strong>{{ job_memory }}</div>
          <div><strong>Job Timeout Seconds:</strong> {{ job_timeout_seconds }}</div>  
          <div><strong>Resource Requirements:</strong>{{ resource_requirements }}</div>
          <div><strong>Platform Capabilities:</strong> {{ platform_capabilities }}</div>
          <div><strong>uLimits:</strong>{{ ulimits }}</div>
          <div><strong>Retry Strategy:</strong> {{ retry_strategy }}</div>
        </div>
      </div>
  </div>
</template>

<script>
import Collapser from '../Generics/Collapser.vue'
export default {
  components: {
    Collapser
  },
  props: {
    job_queue: String,
    job_name: String,
    aws_job_id: String,
    container: String,
    container_account: String,
    container_command:Array,
    container_version: String,
    container_exit_code: String,
    aws_job_definition: String,
    job_role: String,
    job_vcpus: String,
    job_memory: String,
    job_timeout_seconds: String,
    retry_strategy: String,
    container_command: String,
    container_parameters: String,
    resource_requirements: String,
    platform_capabilities: String,
    ulimits: String,
    ecs_cluster: String,
    ecs_task_id: String,
    ecs_task_group: String,
    availability_zone: String,
    ec2_instanceId: String,
    ui_id: String,
    ec2_instance_type: String,
    ec2_instance_vcpu: String,
    ec2_instance_memory: String,
    cpu_architecture: String,
    ecs_agent_version: String,
    docker_version: String,
    ecs_instance_registered_at: String,
    ecs_instance_running_tasks: String,
    ami_id: String,
    ami_name: String,
    ami_creation_date: String,
    ami_description: String,
  },
  computed: {
    region(){
      if(this.availability_zone!==undefined){
        return this.availability_zone.slice(0,-1)
      }
    }
  },
  data() {
    return {
      hide:true
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    }
  }
}
</script>

<style scoped>
  .col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2;
  }
  
  .job-details-container {
    text-align: center;
    border: 0.5px solid lightgray;
  }

  .job-details-row{
    display: flex;
  }
</style>

