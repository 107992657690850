<template>
  <div>
    <font-awesome-icon v-if="jobLoading" :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
    <div class="job-details-container">
      <table class="file-metadata">
        <thead>
        <th>Dataset</th>
        <th>Total Files <small>(existing/total)</small></th>
        </thead>
        <tbody>
          <td>{{this.file.dataset}}</td>
          <td>{{this.file.urls.filter(url => url.exists).length}}/{{this.file.urls.length}}</td>
        </tbody>
        <thead>
          <th>URL</th>
          <th v-if="this.file.urls.filter(url =>url.provided_by !==undefined && url.provided_by.length > 0).length > 0">Provided By {{this.file.urls.filter(url => url.provided_by.length > 0)[0].length}}</th>
        </thead>
        <tbody v-for="url, index in this.file.urls" :key="index">
          <tr>
            <td v-if="url.exists"><a :href="getS3URL(url)" target="_blank">{{ url.url }}</a></td>
            <td v-if="!url.exists">{{ url.url }}</td>
            <td v-if="this.file.urls.filter(url => url.provided_by !==undefined && url.provided_by.length > 0).length > 0"><div v-for="provided, index in url.provided_by" :key="index"><a :href="this.$route.path.split('/JobDetails')[0] + '/JobDetails/' + this.project + '/' + this.workflow + '/' + provided.step + '/' + provided.job_id">{{provided.step}} : {{provided.job_id}}</a></div></td>
          </tr>
        </tbody>
      </table>     
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Collapser from '../Generics/Collapser.vue'

export default {
  components: {
    Collapser
  },
  props: {
    file: Object,
    workflow: String,
    project: String
  },
  computed: {
    ...mapGetters({
      job: 'getJob',
      jobLoading: 'jobLoading',
    })
  },
  data() {
    return {
      hide: true
    }
  },
  methods: {
    toggleElement(){
      this.hide = !this.hide
    },
    getS3URL(url){
      const s3Prefix = 'https://s3.console.aws.amazon.com/s3/buckets/';
      const urlSplit = url.url.split('s3://')[1].split('/')
      const regionPrefix = '?region=eu-west-1&prefix=';
      const lastPart = urlSplit.slice(1).join('/')
      return s3Prefix + urlSplit[0] + regionPrefix + lastPart
    }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }

  td {
    width:15rem;
  }

</style>